var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('ERow',[_c('ECol',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ReportTable',{attrs:{"name":"routers","sort-by":"inserted_at","item-key":"id","headers":_vm.headers,"loading":_vm.reportStore.loading,"sort-desc":true,"provider":_vm.provider,"footer-props":{
          'items-per-page-options': [100, 500, 1000],
        },"show-select":_vm.canDeleteRouters,"filter-fields":_vm.filterFields},scopedSlots:_vm._u([{key:"actions-right",fn:function(){return [(_vm.canDeleteRouters)?_c('v-btn',{attrs:{"dark":"","color":"error","small":"","loading":_vm.reportStore.loading},on:{"click":_vm.deleteAll}},[_vm._v("\n            Delete\n          ")]):_vm._e()]},proxy:true},{key:"item.serialNumber",fn:function({ item }){return [_c('span',{staticClass:"cursor-pointer primary--text",on:{"click":function($event){return _vm.routerStore.openDialog(item)}}},[_vm._v("\n            "+_vm._s(item.serialNumber)+"\n          ")]),_vm._v(" "),_c('OpenInNewWindowButton',{attrs:{"target-link":`${item.rmsUrl}`,"hint":"Open In RMS"}})]}},{key:"item.cameras",fn:function({ item }){return [_c('CamerasColumn',{attrs:{"item":item},on:{"open-camera-dialog":(camera) => _vm.cameraDialogStore.openDialog({ camera })}})]}},{key:"item.sims",fn:function({ item }){return [_c('SimsColumn',{attrs:{"item":item},on:{"open-sms-history-dialog":_vm.openSmsHistoryDialog}})]}},{key:"item.projects",fn:function({ item }){return [(item.sims && item.sims.length > 0)?_c('div',_vm._l((item.projects),function(project,index){return _c('span',{key:`${project.id}${index}`,staticClass:"cursor-pointer primary--text",on:{"click":function($event){return _vm.projectStore.openDialog(project.exid)}}},[_vm._v("\n              "+_vm._s(index !== item.projects.length - 1
                  ? `${project.name},`
                  : project.name)+"\n            ")])}),0):_c('span',[_vm._v("-")])]}},{key:"item.billPayers",fn:function({ item }){return [(item.billPayers && item.billPayers.length > 0)?_c('div',_vm._l((item.billPayers),function(billPayer,index){return _c('span',{key:billPayer.name},[_vm._v("\n              "+_vm._s(index !== item.billPayers.length - 1
                  ? `${billPayer.name},`
                  : billPayer.name)+"\n            ")])}),0):_c('span',[_vm._v("-")])]}},{key:"item.lastSeen",fn:function({ item }){return [(item.battery)?_c('div',[_c('span',{staticClass:"primary--text cursor-pointer",on:{"click":function($event){return _vm.openBatteryReadingsDialog(item.cameras[0].exid)}}},[_vm._v("\n              "+_vm._s(item.battery.lastVoltage)+"V ("+_vm._s(_vm.$moment(item.battery.lastSeen).fromNow())+")\n            ")])]):_c('div',[_vm._v("-")])]}},{key:"item.lastSmsAt",fn:function({ item }){return [_vm._v("\n          "+_vm._s(_vm.formatDate(item.lastSmsAt))+"\n        ")]}},{key:"item.powerType",fn:function({ item }){return [_vm._v("\n          "+_vm._s(_vm.toTitleCase(item.powerType))+"\n        ")]}},{key:"item.lastSms",fn:function({ item }){return [_c('EReadMore',{attrs:{"content":item.lastSms,"max-length":45}})]}},{key:"item.status",fn:function({ item }){return [(_vm.isActiveRouter(item.status))?_c('span',{staticClass:"green--text"},[_vm._v("\n            Active\n          ")]):_c('span',{staticClass:"red--text"},[_vm._v(" Inactive ")])]}}])}),_vm._v(" "),(_vm.canViewSims)?_c('SmsHistoryDialog',{attrs:{"sim":_vm.selectedSimNumber,"selected-sim":_vm.selectedSim,"read-only":!_vm.canSendSms},model:{value:(_vm.showSmsHistoryDialog),callback:function ($$v) {_vm.showSmsHistoryDialog=$$v},expression:"showSmsHistoryDialog"}}):_vm._e(),_vm._v(" "),_c('BatteryReadingsDialog',{attrs:{"id":_vm.batteryId,"show-dialog":_vm.showBatteryReadingsDialog},on:{"closed":function($event){return _vm.closeBatteryReadingsDialog()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }