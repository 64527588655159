export default [
  {
    value: "serialNumber",
    text: "Serial Number",
    width: 85,
    sortable: true,
    filterable: true,
  },
  {
    value: "kitName",
    text: "Kit Name",
    width: 100,
    sortable: false,
  },
  {
    value: "kitSerial",
    text: "Kit Serial",
    width: 100,
    sortable: false,
  },
  {
    value: "cameras",
    text: "Cameras",
    width: 200,
    sortable: false,
    filterable: true,
    toStringFn: (item, key) =>
      item[key]?.map((camera) => camera.name).join(",") || "-",
  },
  {
    value: "projects",
    text: "Projects",
    width: 200,
    sortable: false,
    filterable: true,
    toStringFn: (item, key) =>
      item[key]?.map((camera) => camera.name).join(",") || "-",
  },
  {
    value: "sims",
    text: "SIMs",
    width: 150,
    sortable: false,
    filterable: true,
    toStringFn: (item, key) =>
      item[key]?.map((sim) => sim.number).join(",") || "-",
  },
  {
    value: "billPayers",
    text: "Bill Payers",
    width: 150,
    sortable: false,
    filterable: true,
    toStringFn: (item, key) =>
      item[key]?.map((sim) => sim.name).join(",") || "-",
  },
  {
    value: "lastSms",
    text: "Last SMS",
    width: 340,
    sortable: true,
    filterable: true,
  },
  {
    value: "lastSmsAt",
    text: "Last SMS At",
    width: 130,
    sortable: true,
    filterable: true,
  },
  {
    value: "lastSeen",
    text: "Battery",
    width: 150,
    sortable: true,
    filterable: true,
  },
  {
    value: "status",
    text: "Status",
    width: 65,
    sortable: true,
    filterable: true,
    align: "center",
  },
  {
    value: "vpnServer",
    text: "VPN Server",
    width: 90,
    sortable: true,
    filterable: true,
  },
  {
    value: "vpnUserId",
    text: "VPN User ID",
    width: 100,
    sortable: true,
    filterable: true,
  },
  {
    value: "vpnPassword",
    text: "VPN Password",
    width: 110,
    sortable: false,
    filterable: true,
  },
  {
    value: "routerType",
    text: "Router Type",
    width: 100,
    sortable: true,
    filterable: true,
  },
  {
    value: "routerUserId",
    text: "Router User ID",
    width: 80,
    sortable: true,
    filterable: true,
  },
  {
    value: "routerPassword",
    text: "Router Password",
    width: 120,
    sortable: true,
    filterable: true,
  },
  {
    value: "routerHttpPort",
    text: "Router HTTP Port",
    width: 90,
    align: "center",
    sortable: true,
    filterable: true,
  },
  {
    value: "powerType",
    text: "Power Type",
    width: 75,
    sortable: true,
    filterable: true,
  },
  {
    value: "powerSchedule",
    text: "Power Schedule",
    width: 80,
    sortable: true,
    filterable: true,
  },
]
